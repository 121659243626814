<template>
  <div class="mm-detail">
      <div v-if="!isMobile" class="match-detail">
          <div class="ma-top">
              <div class="topleft">


              <el-card shadow="never" ref="maLeft"
              class="detail-box left-box" @scroll="leftScroll()"
              @mouseover="changeScrollFlag(false)">
                    <template #header>
                        <span>{{$t('message.navbar.ship_date')}}</span>
                    </template>
                    <el-descriptions class="margin-top"  :column="1" >
                        <el-descriptions-item :label="$t('message.shipdate.vessel_name')">{{lang=='en'?shipdateForm.shipEname:shipdateForm.shipCname}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.shipdate.dwt')">{{shipdateForm.dwt}}{{$t('message.T')}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.shipdate.liner_cname')">{{lang=='en'?shipdateForm.linerEname:shipdateForm.linerCname}}</el-descriptions-item>
                        <el-descriptions-item :label="$t('message.shipdate.company_name')">{{shipdateForm.companyName}}</el-descriptions-item>
                        <el-descriptions-item >
                            <el-table :data="this.loadingPortForm" style="width: 100%;padding-left: 0px;">
                                <el-table-column v-if="lang=='en'" prop="loadingPortEname" :label="$t('message.shipdate.loading_port_ename')" width="100"> </el-table-column>
                                <el-table-column v-else prop="loadingPortCname" :label="$t('message.shipdate.loading_port_ename')" width="100"> </el-table-column>
                                <el-table-column prop="laycan" :label="$t('message.shipdate.laycan')" width="130">
                                    <template #default="scope">
                                        <i class="el-icon-time"></i>
                                        <span style="margin-left: 5px" type="date" value-format="yyyy-MM-dd">{{ scope.row.laycanStart}} ~ {{scope.row.laycanEnd}}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                          <el-table :data="this.dischargePortForm" style="width: 100%">
                            <el-table-column  v-if="lang=='en'" prop="dischargePortEname" :label="$t('message.shipdate.discharge_port_ename')" width="100"> </el-table-column>
                            <el-table-column  v-else prop="dischargePortCname" :label="$t('message.shipdate.discharge_port_ename')" width="100"> </el-table-column>
                            <el-table-column  prop="laycanEnd" :label="$t('message.shipdate.getporttime')" width="130">
                              <template #default="scope">
                                <i class="el-icon-time"></i>
                                <span style="margin-left: 5px">{{scope.row.laycanEnd}}</span>
                              </template>
                            </el-table-column>
                            </el-table>
                        </el-descriptions-item>
                        <el-descriptions-item :label="$t('message.shipdate.handling_capacity')">{{shipdateForm.handlingCapacity}}</el-descriptions-item>
                        <el-descriptions-item :label="$t('message.shipdate.intent_cargo')">{{shipdateForm.intentCargo}}</el-descriptions-item>
                        <el-descriptions-item :label="$t('message.shipdate.senderEmail')">{{shipdateForm.senderEmail}}<el-button v-if="!shipdateForm.myFlag" size="mini" style="margin-left:10%" @click="lookvpsender">{{$t('message.check')}}</el-button></el-descriptions-item>
                        <el-descriptions-item :label="$t('message.shipdate.sendTime')">{{shipdateForm.sendTime}}</el-descriptions-item>
                    </el-descriptions>
              </el-card>
              <!-- <div class="vs-box">
                  <img src="../../assets/img/vs.png" />

              </div> -->

              <el-card shadow="never" ref="maRight" class="detail-box right-box" @scroll="rightScroll()"
              @mouseover="changeScrollFlag(true)">
                    <template #header>
                        <span>{{$t('message.search.cargoname')}}</span>
                    </template>
                  <el-descriptions class="margin-top"  :column="1" >
                      <el-descriptions-item :label="$t('message.cargo.cargoName')">{{lang=='en'?tagRight.cargoEname:tagRight.cargoCname}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.quantity')">{{tagRight.quantity}}{{$t('message.T')}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.laycan')">{{tagRight.laycanStart}} ~ {{tagRight.laycanEnd}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.loadingport')">{{lang=='en'?tagRight.loadingPortEname:tagRight.loadingPortCname}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.dischargingport')">{{lang=='en'?tagRight.dischargePortEname:tagRight.dischargePortCname}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.shorthandname')">{{tagRight.shorthandname}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.commission')">{{tagRight.commission}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.loadrate')">{{tagRight.loadrate}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.dischargerate')">{{tagRight.dischargerate}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.memo')">{{tagRight.memo}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.sender_time')">{{tagRight.sendTime}}</el-descriptions-item>
                      <el-descriptions-item :label="$t('message.cargo.sender')">{{tagRight.senderEmail}}<el-button v-if="!tagRight.myFlag" size="mini" style="margin-left:10%" @click="lookcargosender">{{$t('message.check')}}</el-button></el-descriptions-item>
                  </el-descriptions>
                  <collapse-transition name="draw" >
                      <div class="collapse-wrap" v-show="cargoisActive" >
                          <slot>
                              <el-descriptions  :column="1">
                                  <el-descriptions-item :label="$t('message.cargo.maxWeight')">{{tagRight.maxWeight}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.maxsize')">{{tagRight.maxsize}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.maxLength')">{{tagRight.maxLength}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.maxWidth')">{{tagRight.maxWidth}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.maxHeight')">{{tagRight.maxHeight}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.cargotype')">{{lang=='en'?tagRight.cargoTypeEname:tagRight.cargoTypeCname}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.loadrequest')">{{tagRight.loadrequest}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.package')">{{tagRight.package}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.sf')">{{tagRight.sf}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.crane')">{{tagRight.crane}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.term')">{{tagRight.term}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.frt')">{{tagRight.frt}}</el-descriptions-item>
                                  <el-descriptions-item :label="$t('message.cargo.currencyUnit')">{{tagRight.currencyUnit}}</el-descriptions-item>
                              </el-descriptions>
                          </slot>
                      </div>
                  </collapse-transition>
                  <div style="text-align: center;position: sticky;bottom:0">
                      <el-button type="text" @click="cargoupanddown">{{$t('message.detailinfo')}}<i id="cargoupdown" class="el-icon-arrow-down"></i>
                      </el-button>
                  </div>
              </el-card>
              </div>
              <h3>{{$t('message.otherinfo')}}</h3>
              <div class="block">
                  <el-carousel indicator-position="outside" height="170px">
                      <el-carousel-item v-for="(item,i) of match_list" :key="i" >
                          <vs-card :matchLeft="item.voyagePlanCardModel" :matchRight="item.cargoCardModel" :collect="VoyageplanCargo"></vs-card>

                      </el-carousel-item>
                  </el-carousel>
              </div>
          </div>
          <div class="resize" title="收缩侧边栏">
              ⋮
          </div>
          <div class="ma-haitu">
              <Mapbox   ref="mychild" :temp='1' ></Mapbox>
          </div>
      </div>
      <div v-if="isMobile" class="match-detail-mobile">
            <div class="ma-top">
              <el-card shadow="never" class="detail-box">
                    <template #header>
                        <span></span>
                    </template>
                    <el-descriptions class="margin-top"  :column="1" >
                        <el-descriptions-item label="Vessal Name">Sea Champion</el-descriptions-item>
                        <el-descriptions-item label="Vessal Type">Bulk Carrier</el-descriptions-item>
                        <el-descriptions-item label="Open Date">2021/02/03--2021/02/07</el-descriptions-item>
                        <el-descriptions-item label="Open Area">eastren mediterrnanean(EMED)</el-descriptions-item>
                        <el-descriptions-item label="Open Port">Piraeus</el-descriptions-item>
                        <el-descriptions-item label="DWT/Draft">488.00 / 11.623</el-descriptions-item>
                        <el-descriptions-item label="Loa / Beam / Depth ">189.96 / 32.20 / 16.50</el-descriptions-item>
                        <el-descriptions-item label="P&I Club/ Class / Flag">the USclub / NKK / Greece</el-descriptions-item>
                        <el-descriptions-item label="Built / Grain / Bale ">2005 / 2125.36 / 2113.38</el-descriptions-item>
                        <el-descriptions-item label="Hatch / Hold / Deck_num">5 / 5 / SINGLE DECK</el-descriptions-item>
                        <el-descriptions-item label="Gear">4 x 30 mts cranes + 4 x 12 cm grabs</el-descriptions-item>
                        <el-descriptions-item label="Sender"><a>Kostas Tsampas</a></el-descriptions-item>
                    </el-descriptions>
              </el-card>
              <div class="vs-box">
                  <img src="../../assets/img/vs.png" />
              </div>
              <el-card shadow="never" class="detail-box">
                    <template #header>
                        <span v-if="this.tag === 'cargo'">{{$t('message.search.cargoname')}}</span>
                        <span v-if="this.tag === 'tct'">{{$t('message.tct.tct')}}</span>
                    </template>
                    <el-descriptions class="margin-top"  :column="1" >
                        <el-descriptions-item label="Cargo Name">PROJECT CARGO</el-descriptions-item>
                        <el-descriptions-item label="Cargo Type">None</el-descriptions-item>
                        <el-descriptions-item label="Laycan">2021/02/03--2021/02/28</el-descriptions-item>
                        <el-descriptions-item label="Loading Port">Gebze</el-descriptions-item>
                        <el-descriptions-item label="Discharging Port">Piraeus</el-descriptions-item>
                        <el-descriptions-item label="Quantity">447.66</el-descriptions-item>
                        <el-descriptions-item label="Package">None</el-descriptions-item>
                        <el-descriptions-item label="Term">None</el-descriptions-item>
                        <el-descriptions-item label="Frt / Commission(%)">None / 3.75</el-descriptions-item>
                        <el-descriptions-item label="S.F / L/D Rate">0.00 / 0.0</el-descriptions-item>
                        <el-descriptions-item label="Crane">None</el-descriptions-item>
                        <el-descriptions-item label="Sender"><a>Harish</a></el-descriptions-item>
                    </el-descriptions>
              </el-card>
          </div>
          <div class="ma-haitu"></div>
      </div>
  </div>
</template>

<script>
import { mapGetters  } from 'vuex'
import {searchshipdatebyId,searchvppayed,matchvoyageplan} from "../../api/voyagePlan";
import {searchCargobyId,searchCargopayed} from "../../api/cargo";
import { getMypoints } from "../../api/integral";
import { ElMessageBox } from "element-plus";
import Mapbox from '../../components/mapbox/Mapbox'
import VsCard from '../../components/vs-card/VsCard.vue'
import {searchTonnagepayed} from "../../api/tonnage";


export default {
  components:{ Mapbox,VsCard},
 data(){
   return{
     cargoisActive:false,
       shipdateForm:'',
       tagRight:'',
       shipdate_id:0,//传进来的tonnage信息的id
       tag:'',//传进来的另一个匹配信息标识：‘cargo’或‘tct’
       tag_id:0,//另一个匹配信息的id,
       lang:'',
       scrollFlag:false,
       loadingPortForm:[],
       dischargePortForm:[],
       dischargePort:[],
       portId:[],         //航线中的港口id
       openportlatitude:'',
       openportlongitude:'',
       loadingPortId:[],
       dischargePortId:[],
     match_list:[],
     cargopaiedForm: {
       consume: '',
       giftId: '',
       giftType: '',
       id: ''
     },
     vppaiedForm: {
       consume: '',
       giftId: '',
       giftType: '',
       id: ''
     },
     pagination: {
       current: 0,
       pagesize: 4,
       total: 0
     },

   }
 },
 computed:{
     ...mapGetters(['isMobile','shipdateList','cargoList'])
 },
 methods:{
    //经纬度转换
   DegreeConvertBack(value){
     var du = value.split("°")[0];
     var fen = value.split("°")[1].split("′")[0]
     var miao = ((value.split("°")[1]||'').split("′")[1] ||'').split('″')[0];
     if(miao == 'W' || miao == 'S'){
       return -(Math.abs(du) +  (Math.abs(fen)/60));
     }
     else {        //+ Math.abs(miao)/3600)
       return Math.abs(du) + (Math.abs(fen)/60) ;
     }
   },
   initmatch(){
     let ports = []
     searchshipdatebyId(this.shipdate_id).then(response =>{
       if(response.error_code==200){
         this.shipdateForm = response.data.vovagePlanModel
         this.loadingPortForm = response.data.vpLdportModelList
         for (let i=0;i<response.data.vpDchportModelList.length;i++){
           this.dischargePort.push(response.data.vpDchportModelList[i].dischargePortCname)
           //this.dischargePort.push(response.data.vpDchportModelList[i].dischargePortEname)
         }
         this.loadingPortForm = response.data.vpLdportModelList
         //装货港id数组
         this.$refs.mychild.initMap()
         for(let i=0;i<response.data.vpLdportModelList.length;i++){
           this.portId.push(response.data.vpLdportModelList[i].loadingPortId)
           this.loadingPortId.push(response.data.vpLdportModelList[i].loadingPortId)
         }
         this.dischargePortForm = response.data.vpDchportModelList
         //卸货港id数组
         for(let i=0;i<response.data.vpDchportModelList.length;i++){
           this.portId.push(response.data.vpDchportModelList[i].dischargePortId)
           this.dischargePortId.push(response.data.vpDchportModelList[i].dischargePortId)
         }
       //  console.log(this.portId)
         // this.$refs.mychild.initMap()
         this.$refs.mychild.voyagePlan( this.portId,this.loadingPortId,this.dischargePortId)
       }
     })
     searchCargobyId(this.tag_id).then(response =>{
       if(response.error_code==200){
         this.tagRight = response.data
         //画装货港和卸货港
         this.$refs.mychild.drawloadpoint(response.data.loadingPortId)
         this.$refs.mychild.drawdischargepoint(response.data.dischargePortId)
       }
     })
   },
   async lookvpsender() {
     this.vppaiedForm.id = this.shipdate_id
     this.vppaiedForm.consume = false
     this.vppaiedForm.giftType = ''
     if (this.lefttemp == 'true') {  //信息都为自己或已购买
       ElMessageBox.confirm(
           window.vm.$t('message.buycheck'),
           {
             confirmButtonText: window.vm.$t('message.OK'),
             cancelButtonText: window.vm.$t('message.cancel'),
             type: 'info',
           }
       ).then(() => {
         searchTonnagepayed(this.vppaiedForm).then(response => {
           if (response.error_code == 200) {
             this.shipdateForm.senderEmail = response.data.senderEmail
           }
         })
       })
     }
     else if(this.lefttemp == 'false' && this.tagRight.myFlag == 'true'){  //左侧未购买，右侧已购买，当前左侧信息可以免费匹配
       ElMessageBox.confirm(
           window.vm.$t('message.freechance'),
           window.vm.$t('message.Tips'),
           {
             confirmButtonText: window.vm.$t('message.OK'),
             cancelButtonText: window.vm.$t('message.cancel'),
             type: 'info',
           }
       ).then(() => {
         this.vppaiedForm.consume = true  //触发免费匹配
         this.vppaiedForm.id = this.shipdate_id
         this.vppaiedForm.giftId = this.tag_id
         this.vppaiedForm.giftType = 2
         this.vppaiedForm.matchType = 3
         searchCargopayed(this.vppaiedForm).then(response => {
           if (response.error_code == 200) {
             this.shipdateForm.senderEmail = response.data.senderEmail
           }
         })
       })
     }
     else{
       await getMypoints().then(response => {
         this.currentPoint = response.data
       })
       if (this.currentPoint >= 1) {
         ElMessageBox.confirm(
             window.vm.$t('message.isbuy'),
             window.vm.$t('message.Tips'),
             {
               confirmButtonText: window.vm.$t('message.OK'),
               cancelButtonText: window.vm.$t('message.cancel'),
               type: 'info',
             }
         ).then(() => {
           this.vppaiedForm.consume = true  //积分够，点击确认购买，将consume改为true
           searchvppayed(this.vppaiedForm).then(response => {
             if (response.error_code == 200) {
               this.shipdateForm.senderEmail = response.data.senderEmail
             }
           })
         })
       } else {           //积分不足，前去充值
         ElMessageBox.confirm(
             window.vm.$t('message.gotobuy'),
             window.vm.$t('message.Tips'),
             {
               confirmButtonText: window.vm.$t('message.OK'),
               cancelButtonText: window.vm.$t('message.cancel'),
               type: 'info',
             }
         ).then(() => {
           this.$router.push("/person/personalmessage/deposit")
         })
       }
     }
   },
   async lookcargosender() {
     this.cargopaiedForm.id = this.tag_id
     this.cargopaiedForm.consume = false
     this.cargopaiedForm.giftType = ''
     if (this.righttemp == 'true') {
       ElMessageBox.confirm(
           window.vm.$t('message.buycheck'),
           {
             confirmButtonText: window.vm.$t('message.OK'),
             cancelButtonText: window.vm.$t('message.cancel'),
             type: 'info',
           }
       ).then(() => {
         //  this.paiedForm.consume = true  积分够，点击确认购买，将consume改为true
         searchCargopayed(this.cargopaiedForm).then(response => {
           if (response.error_code == 200) {
             this.tagRight.senderEmail = response.data.senderEmail
           }
         })
       })
     }
     else if(this.shipdateForm.myFlag == 'true' && this.righttemp == 'false'){
       ElMessageBox.confirm(
           window.vm.$t('message.freechance'),
           window.vm.$t('message.Tips'),
           {
             confirmButtonText: window.vm.$t('message.OK'),
             cancelButtonText: window.vm.$t('message.cancel'),
             type: 'info',
           }
       ).then(() => {
         this.cargopaiedForm.id = this.tag_id
         this.cargopaiedForm.consume = true  //触发免费匹配
         this.cargopaiedForm.giftId = this.shipdate_id
         this.cargopaiedForm.matchType = 3
         this.cargopaiedForm.giftType = 4
         searchvppayed(this.cargopaiedForm).then(response => {
           if (response.error_code == 200) {
             this.tagRight.senderEmail = response.data.senderEmail
           }
         })
       })
     }
     else{
       await getMypoints().then(response => {
         this.currentPoint = response.data
       })
       if (this.currentPoint >= 1) {
         ElMessageBox.confirm(
             window.vm.$t('message.isbuy'),
             window.vm.$t('message.Tips'),
             {
               confirmButtonText: window.vm.$t('message.OK'),
               cancelButtonText: window.vm.$t('message.cancel'),
               type: 'info',
             }
         ).then(() => {
           this.paiedForm.consume = true  //积分够，点击确认购买，将consume改为true
           searchCargopayed(this.cargopaiedForm).then(response => {
             if (response.error_code == 200) {
               this.tagRight.senderEmail = response.data.senderEmail
             }
           })
         })
       } else {           //积分不足，前去充值
         ElMessageBox.confirm(
             window.vm.$t('message.gotobuy'),
             window.vm.$t('message.Tips'),
             {
               confirmButtonText: window.vm.$t('message.OK'),
               cancelButtonText: window.vm.$t('message.cancel'),
               type: 'info',
             }
         ).then(() => {
           this.$router.push("/person/personalmessage/deposit")
         })
       }
     }
   },
   dragControllerDiv: function () {
     var resize = document.getElementsByClassName('resize');
     var left = document.getElementsByClassName('ma-top');
     var mid = document.getElementsByClassName('ma-haitu');
     var box = document.getElementsByClassName('match-detail');
     var map = document.getElementsByClassName('mapboxgl-canvas');
     let _this = this
     for (let i = 0; i < resize.length; i++) {
       // 鼠标按下事件
       resize[i].onmousedown = function (e) {
         //颜色改变提醒
         resize[i].style.background = '#818181';
         var startX = e.clientX;
         resize[i].left = resize[i].offsetLeft- window.innerWidth * 0.12;
         // 鼠标拖动事件
         document.onmousemove = function (e) {
           var endX = e.clientX;
           var moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
           var maxT = box[i].clientWidth - resize[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度
           if (moveLen < 32) moveLen = 32; // 左边区域的最小宽度为32px
           if (moveLen > maxT - 300) moveLen = maxT - 300; //右边区域最小宽度为150px

           resize[i].style.left = moveLen; // 设置左侧区域的宽度

           for (let j = 0; j < left.length; j++) {
             left[j].style.width = moveLen + 'px';
             mid[j].style.width = (box[i].clientWidth - moveLen - 50) + 'px';
             _this.$refs.mychild.onResize()
           }
         };
         // 鼠标松开事件
         document.onmouseup = function (evt) {
           //颜色恢复
           resize[i].style.background = '#d6d6d6';
           document.onmousemove = null;
           document.onmouseup = null;
           resize[i].releaseCapture && resize[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
         };
         resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
         return false;
       };
     }
   },
    changeScrollFlag(flag){
        this.scrollFlag = flag
    },
    //控制左右滚动条同步
    leftScroll() {
       let top = $(".left-box").scrollTop()
       if(!this.scrollFlag){ //当前操作的是左侧滚动条
           $(".right-box").scrollTop(top)
       }
    },
    rightScroll() {
       let top = $(".right-box").scrollTop()
       if(this.scrollFlag){ //当前操作的是右侧滚动条
           $(".left-box").scrollTop(top)
       }
    },
   cargoupanddown(){
     var div = document.getElementById('cargoupdown');
     if( this.cargoisActive == false){
       this.cargoisActive = true
       div.className = 'el-icon-arrow-up'
     }else {
       this.cargoisActive = false
       div.className = 'el-icon-arrow-down'
     }
   },
   VoyageplanCargo(){
     const params = {
       query:{},
       pagination: this.pagination
     }
     matchvoyageplan(params).then(response =>{
       if(response.error_code == 200){
         this.match_list=response.datas
         this.pagination.total = response.pagination.total
       }
     })
   },
 },
  mounted(){
    this.dragControllerDiv()
  },
 created(){
     const { shipdate_id , tag , tag_id  } = this.$route.params
     this.shipdate_id = shipdate_id
     this.tag = tag,
     this.tag_id = tag_id
      this.lefttemp=this.$route.query.lefttemp
      this.righttemp=this.$route.query.righttemp
     this.lang = localStorage.getItem('lang')
     this.initmatch()
   this.VoyageplanCargo()
 }
}
</script>

<style lang="scss">
.mm-detail{
    width:100%;
    height:100%;
    .match-detail{
        width:98%;
        //min-width: 500px;
        height:100%;
        .ma-top{
            float: left;
            width:45%;
            height:100%;
            /*display: flex;*/
            //overflow-y: auto;
            .el-card{
                height:100%;
                //overflow-y: scroll;
                overflow:auto !important;
            }
            .topleft {
                width: 100%;
                display: flex;
                height: 65%;
            }
            .block{
                width: 100%;
                /*height: 40%;*/
                bottom: 0px;
            }
            .el-carousel__item h3 {
                color: #475669;
                font-size: 14px;
                opacity: 0.75;
                line-height: 150px;
                margin: 0;
                text-align: center;
            }

            .el-carousel__item:nth-child(2n) {
                background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n + 1) {
                background-color: #d3dce6;
            }
            .detail-box{
                flex:5;
                //border:2px solid #409eff;
                //border-radius: 10px;
                //background: pink;
                .el-card__header{
                    //height:5%;
                    padding:5px 20px;
                    //background: cornflowerblue;
                    border-bottom: 2px solid #409eff;
                    font-size: 16px;
                }
                .el-card__body{
                    padding: 10px 20px;
                    overflow-y: auto;
                }
                .el-descriptions :not(.is-bordered) td{
                    padding-bottom: 6px !important;
                }
                .el-descriptions__label{
                    font-weight: 600;
                }
            }
            .vs-box{
                flex:2;
                //background: chartreuse;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width:100%;
                    height:60%;
                }
            }
        }
        #map{
            height:100%;
            width:100%;

            margin:1.5% 2% 1.5% 1.5%;
            //background: pink;
        }
        .ma-haitu{
            float: left;
            width: 52%;
            height: 95%;
            /*background: pink;*/
            margin: 1%;

        }
        .resize {
            cursor: col-resize;
            float: left;
            position: relative;
            top: 45%;
            background-color: #d6d6d6;
            border-radius: 5px;
            margin-top: -10px;
            width: 10px;
            height: 50px;
            background-size: cover;
            background-position: center;
            /*z-index: 99999;*/
            font-size: 32px;
            color: white;
        }
        /*拖拽区鼠标悬停样式*/
        .resize:hover {
            color: #444444;
        }
    }
    .match-detail-mobile{
        width:98%;
        height:100%;
        .ma-top{
            width:100%;
            height:80%;
            display: flex;
            flex-direction: column;
            .el-card{
                overflow:auto !important;
            }
            .detail-box{
                width:100%;
                height:40%;
                //flex:5;
                //border:2px solid #409eff;
                //border-radius: 10px;
                //background: pink;
                .el-card__header{
                    //height:5%;
                    padding:5px 20px;
                    //background: cornflowerblue;
                    border-bottom: 2px solid #409eff;
                    font-size: 16px;
                }
                .el-card__body{
                    padding: 10px 20px;
                    overflow-y: auto;
                }
                .el-descriptions :not(.is-bordered) td{
                    padding-bottom: 6px !important;
                }
                .el-descriptions__label{
                    font-weight: 600;
                }
            }
            .vs-box{
                width:100%;
                height:20%;
                //flex:1;
                //background: chartreuse;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width:40%;
                    height:100%;
                }
            }
        }
        .ma-haitu{
            width:100%;
            height:52%;
            background: pink;
            margin-top: 1%;
        }
    }
}
</style>
